<template>
  <h2 class="text-black text-center">Signup</h2>
  <hr>

  <p v-if="showVerifiedEmailSentMsg">
    A verification email has been sent to <span style="color: #af96ff;">{{ registerForm.email }}</span>.
    It will expire in 1 hour.
  </p>

  <template v-else>
    <div class="form-group mb-3">
      <input type="text"
             class="form-control"
             v-model="registerForm.name"
             :class="{'is-invalid': errorFor('name')}"
             placeholder="Name" />

      <v-errors :errors="errorFor('name')"></v-errors>
    </div>

    <div class="form-group mb-3">
      <input type="text"
             class="form-control"
             v-model="registerForm.email"
             :class="{'is-invalid': errorFor('email')}"
             placeholder="Email" />

      <v-errors :errors="errorFor('email')"></v-errors>
    </div>

    <div class="form-group mb-3">
      <input type="password"
             v-model="registerForm.password"
             :class="{'is-invalid': errorFor('password')}"
             class="form-control"
             placeholder="Password" />

      <v-errors :errors="errorFor('password')"></v-errors>
    </div>

    <div class="form-group mb-3">
      <input type="password"
             v-model="registerForm.password_confirmation"
             :class="{'is-invalid': errorFor('password_confirmation')}"
             class="form-control"
             placeholder="Confirm Password" />

      <v-errors :errors="errorFor('password_confirmation')"></v-errors>
    </div>

    <button @click.prevent="register"
            :disabled="loading"
            class="btn btn-block text-white font-weight-bold"
            style="background-color: #23AFB7">
      Start your free trial and start making more money with your Amazon account today
    </button>

    <div class="text-center">
      <p class="mt-3">Already Have an Account? <router-link :to="{name: 'login'}">Login </router-link> here!</p>
    </div>
  </template>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      showVerifiedEmailSentMsg: false,
      registerForm: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        device: 'web',
        i_code: this.$route.query.i_code,
        invitation: this.$route.query.invitation
      }
    }
  },
  methods: {
    register() {
      this.allErrors = null;
      this.loading = true;

      this.$store.dispatch('auth/register', this.registerForm)
          .then(() => {
            this.showVerifiedEmailSentMsg = true;

            window.gtag('event', 'signup', {
              'email': this.registerForm.email
            });
          })
          .catch((err) => {
            this.allErrors = err.errors
          })
          .finally(() => this.loading = false);
    }
  }
}
</script>

<style scoped>
@import "../../assets/css/auth.css";
</style>